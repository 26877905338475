import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { DeserializableModel } from '@shared/models/deserializable.model';
import { map } from 'rxjs/operators';
import { ApiRestOptions } from '@app/shared/interfaces/api-rest-options';

@Injectable({
  providedIn: 'root',
})
export class ApiRestService {
  private url = environment.apiUrl;

  getUrl() {
    return this.url;
  }

  constructor(private http: HttpClient) {}

  postVoid(
    endpoint: string,
    body: unknown,
    options?: ApiRestOptions,
  ): Observable<void> {
    return this.http
      .post(`${this.url}/${endpoint}`, body, options)
      .pipe(map(() => void 0));
  }

  post<T extends DeserializableModel>(
    constructorFn: new () => T,
    endpoint: string,
    body: unknown,
    options?: ApiRestOptions,
  ): Observable<T> {
    return this.http
      .post<DeserializableModel>(`${this.url}/${endpoint}`, body, {
        ...options,
        observe: 'body',
      })
      .pipe(map((resData) => new constructorFn().deserialize(resData)));
  }

  get<T extends DeserializableModel>(
    endpoint: string,
    options?: ApiRestOptions,
  ): Observable<T> {
    return this.http.get<T>(`${this.url}/${endpoint}`, {
      ...options,
      observe: 'body',
    });
  }

  /**
   * Uploads a file to the given endpoint.
   * @param endpoint The endpoint where the file will be uploaded.
   * @param formData The FormData object that includes the file and additional data.
   * @returns An observable of the response.
   */
  upload<T extends DeserializableModel>(
    constructorFn: new () => T,
    endpoint: string,
    formData: FormData,
    options?: ApiRestOptions,
  ): Observable<T> {
    return this.http
      .post<T>(`${this.url}/${endpoint}`, formData, {
        ...options,
        headers: new HttpHeaders({ enctype: 'multipart/form-data' }),
        observe: 'body',
      })
      .pipe(map((resData) => new constructorFn().deserialize(resData)));
  }

  /**
   * Deletes a resource on the server.
   * @param endpoint The endpoint where the resource will be deleted.
   * @returns An observable of the response.
   */
  delete(endpoint: string, options?: ApiRestOptions): Observable<void> {
    return this.http
      .delete<void>(`${this.url}/${endpoint}`, {
        ...options,
        observe: 'response',
      })
      .pipe(map(() => void 0));
  }
}
